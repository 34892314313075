body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
/************* COLORS BEGIN /*************/
:root {
    --color-body-background: #24292f;
    --color-body-text: rgb(31, 31, 31); 
    
    --color-section-background: #ffffff;
    --color-section-heading-background: #ffffff;
    --color-section-heading: #6f767e;
    --color-section-border: #5e5e5e;
    --color-numbers: #9ba4af;
    --color-highlighted-background: #c1c1c1;
    --color-highlighted-text: black;
    --color-clicks: gray;

    --color-variable: #869190;
    --color-playground-border: #c3cdcc;
    --color-playground-bg: #f6fbff;
    --color-bucket-border: #c3cdcc;
    --color-code-bg: #f4faff; /* #24292f; */
    --color-code: #4b4f54; /* #9ba4af; */
    --color-code-linenumber: #c1c7cf;
    --color-sidebar-selected-bg: #275f60;
    --color-arrayitems: #3D3DEB;
    --color-theme-variant1: #4cb8ba;

}
body, .MuiAppBar-root, .drawerwrapper {
    background-color: var(--color-body-background) !important;
    box-shadow: none !important;
}
#root{
    background: var(--color-body-background) !important;
}
.MuiDrawer-paper {
    background: var(--color-body-background) !important;
    color: white !important;
    /* border-color: gray !important;  */
}
.algocontainer {
    background: var(--color-section-background);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 22px;
    /* min-height: 100vh; */
    /* color: var(--color-body-text); */
}
.textfield input, .textfield input:focus {
    color: var(--color-body-text) !important;
    border: none !important;
}
.section {
    background-color: var(--color-section-background);
    /* border: solid 1px var(--color-section-border); */
}
.numbers {    
    color: var(--color-numbers);
}
.section_heading {
    background-color: var(--color-section-heading-background); 
    color: var(--color-section-heading);
}
.clicks {
    color: var(--color-clicks);
}
/************* COLORS END /*************/

/***** NON-LAYOUT BEGIN *****/

.section {
    position: relative;
}
.section_heading {
    position: absolute;
    top: -14px;
    left: 10px;
    padding: 0 5px;
}

.algocontainer {
    padding: 20px;
    min-height: 90vh;
}
.algo_header {
    /* text-align: center; */
    margin-bottom: 40px;
}
.working {
    padding-bottom: 35px !important; /* for clicks */
    position: relative; /* for clicks */
}

.section {
    /* text-align: center; */
    margin: 10px;
    border-radius: 2px;
}
.section-body {
    /* padding: 30px; */
}

.numbers {    
    font-size: smaller;
    margin: 20px 0;
}
.clicks {
    position: absolute;
    right: 35px;
    bottom: 10px;
}

.textfield {
    margin: 0px !important;
    margin-top: -6px !important;
    width: 60px !important;
}
.textfield input:focus {
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}
/***** NON-LAYOUT END *****/

/***** LAYOUT BEGIN *****/

.algocontainer {
    /* margin: 0 auto; */
    /* max-width: 600px; */
    box-sizing: border-box;
}

/* default styles here for older browsers. I go for a 600px - 960px width max but using percentages */
@media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
}
@media only screen and (min-width: 1440px) {
    /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
    /* for sumo sized (mac) screens */
}
@media only screen and (max-device-width: 480px) {
    /* styles for mobile browsers smaller than 480px; (iPhone) */
    .algocontainer {
        max-width: 100%;
        width: 100%;
    }
}
@media only screen and (device-width: 768px) {
    /* default iPad screens */
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    /* For landscape layouts only */
}


/***** LAYOUT END *****/

a, a:focus, a:active {
    color: inherit;
    text-decoration: none;
}
a:hover {
    
    text-decoration: none;
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}


.highlight {
    background-color: #b4e3e4 ;
    padding: 5px;
    border-radius: 5px;
}

.highlight-outlined {
    border: solid 1px #b4e3e4 ;
    padding: 5px;
    border-radius: 5px;
}

.variable {
    padding: 10px;
    border-radius: 3px;
    border: solid 1px var(--color-variable);
    margin-bottom: 20px;
    position: relative;
    width: fit-content;
}
.variable-title {
    position: absolute;
    bottom: -22px;
    right: 0;
    width: 100%;
    text-align: center;
    color: var(--color-variable);
    font-size: smaller;
}
.playground {
    padding: 20px;
    border-radius: 5px;
    border: solid 1px var(--color-playground-border);
    margin-bottom: 20px;

    background: var(--color-playground-bg) !important;
}
.playground .arrayitem {
    border-color: #e1e5e9;
    display: "inline-block";
}
.bucket {
    padding: 10px;
    border-radius: 3px;
    border: solid 1px var(--color-bucket-border);
    /* margin-bottom: 20px; */
}

.bucket-title {
    text-align: center;
    margin-bottom: 8px;
    background-color: var(--color-section-background);
    color: #869190;
    font-size: smaller;
}

.bucket {
    position: relative;
    text-align: left;
}
.bucket-title {
    position: absolute;    
    top: -12px;
    right: 10px;
    padding: 0 5px;
}
.code-and-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    gap: 20px;
}

@media only screen and (max-device-width: 480px) {
    /* styles for mobile browsers smaller than 480px; (iPhone) */
    .code-and-btns {
        flex-direction: column;
    }    
    .algocontainer {
        padding: 10px;
        margin: 0px auto;
    }
    .windowitem .number {
        font-size: small;
        margin-bottom: 2px;
    }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    .code-and-btns {
        flex-direction: column;
    }
}
.code {
    text-align: left;
    /* background-color: var(--color-code-bg); */
    color: var(--color-code);
    border: solid 1px var(--color-playground-border);
    background: var(--color-playground-bg) !important;

    padding: 10px;
    border-radius: 5px;    
    height: 100%;

    flex-grow: 1;
}
.btns {
    flex-grow: 1;
}
.line {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
.linenumber {
    vertical-align: top;
    width: 30px;
    color: var(--color-code-linenumber);
    font-size: small;
}
.line pre {
    flex-grow: 1;
    margin: 5px 0;
}
.selected.line {
    background-color: #b2ffb4;
    color: black;
}
.divider-text-wrapper {
    border-top: solid 1px #777;
    margin: 10px 0;
    margin-left: 36px;
    margin-right: 36px;
    position: relative
}
.divider-text {
    position: absolute;
    top: -8px;
    background: var(--color-body-background);
    padding-left: 6px;
    padding-right: 6px;
    color: #777;
    left: 70px;
    text-transform: uppercase;
    font-size: 11px;
    
}
.MuiDrawer-paper {
    text-transform: uppercase;
}
.MuiToolbar-root {
    text-transform: none;
}
.sidebar-subitem .MuiTypography-body1 {
    /* font-size: 13px !important; */
    text-transform: none;
}
.selected-sidebar-item {
    background-color: var(--color-sidebar-selected-bg);
} 

/********** graph ************/
line {
  stroke: #80878d;
  stroke-width: 1px;
}
text {
  text-anchor: middle;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  cursor: pointer;
}
.node text { 
    font: 12px sans-serif;
    fill: white; 
}
text.pricesplot {
    font-size: 11px;
}


/********* bfs ************/
.popped {
    margin-top: 30px;
    margin-bottom: 20px;

    background-color: var(--color-arrayitems);
    color: white;
    padding: 10px 2px;
    border-right: solid 6px white;
}
.popped-and-queue {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: stretch;
}
.bucket {
    flex-grow: 1;
}

@keyframes newlyadded {
    from {
        margin-left: 100px;
    }
    to {
        margin-right: 0;
    }
}

.queueitem {
    /* background-color: var(--color-theme-variant1); */
    color: white;
    padding: 10px 2px;
    margin-right: 6px;

    /* animation-name: newlyadded; */
    /* animation-duration: 1s; */
}

.result {
    background-color: var(--color-theme-variant1);
    padding: 10px 2px;
}

.hashmap {
    border: solid 1px #666;
}

.arrayitem {
    background-color: var(--color-theme-variant1);
    color: white;
    padding: 10px 2px;
    margin-right: 6px;
    display: inline-block;
}

/******* union find *******/
.info {
    margin-top: 40px;
}
.section.left-align {
    text-align: left;
}

.section-body.unionfind {
    min-height: 50vh;
    padding: 10px !important;
}
.section {
    margin-bottom: 5px;
}
.new.nodes {
    animation-name: newlyadded;
    animation-duration: 5s;
}
@keyframes newlyadded {
    from {
        background-color: #f3b76d;
    }
    to {
        background-color: transparent;
    }
}

.MuiStepLabel-label  {
    text-transform: uppercase;
    cursor: pointer;
}
.Mui-disabled circle {
    fill: var(--color-theme-variant1) !important;
}
.Mui-active circle {
    fill: #e3a424 !important;
}
.Mui-completed path {
    fill: var(--color-theme-variant1) !important;
}
.subtitle {
    font-size: smaller;
    color: darkgray;
    margin-top: 6px;
}

/************* topo sort **************/


.queueitem, .sortitem {
    background-color: var(--color-arrayitems);
    padding: 10px 2px;
    margin-right: 6px;
    color: white;
}
.new {
    animation-name: newlyadded;
    animation-duration: 1s;
}
@keyframes newlyadded {
    from {
        margin-left: 100px;
    }
    to {
        margin-right: 0;
    }
}

/************* min cost **************/
.cell {
    padding: 5px;
    margin-right: 2px;
}
.way {
    margin: 3px 0;
    
}
.cell {
    color: white;
    width: 20px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
}
.cell.selected {
    background-color: azure;
    color: #333;
}
.cell.highlight {
    background-color: var(--color-theme-variant1) !important;
}

/************* min path sum **************/
.gridcell {
    /* color: #666; */
    border: solid 2px aquamarine;
    text-align: center;
    display: inline-block;
    width: 30px;
    padding: 8px 0;
}
.gridcell.selected {
    background-color: #c6ffec;
}
.gridcell.doublyselected {
    background-color: #13b6d3;
}
.grid-container {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 5px;
    color: #666;
}
.grid {
    border: solid 2px aquamarine;    
    display: inline-block;
}

/*********** sliding window ************/
.windowitem {
    color: rgb(77, 86, 88);
    /* padding: 10px 5px; */
    /* border-right: solid 6px white; */
    display: inline-block;
    width: 30px;
    vertical-align: top;
}
.first .number {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: solid 1px var(--color-theme-variant1);;
}
.last .number {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-right: solid 1px var(--color-theme-variant1);;
}
.window {
    margin-top: 10px;
    /* text-align: center; */
}
.windowitem.in .number {
    background-color: var(--color-theme-variant1);;
    color: white;
}
.invisible {
    color: white;
}
.visible {
    z-index: 10;
    position: relative;
}
.windowitem .number {
    border-bottom: solid 1px var(--color-theme-variant1);;
    border-top: solid 1px var(--color-theme-variant1);;
    
    padding: 10px 5px;
}
.arrow {
    font-size: 50px;
    margin-top: -15px;
    margin-bottom: -13px;
    text-align: center;
}
.complete, .complete .bucket-title {
    background-color: var(--color-theme-variant1);
    color: white;
}

/*********** alien dictionary ************/
.fruits {
    background-color: bisque;
    padding: 10px;
    border-radius: 5px;
}
.letter {
    padding: 5px 10px;
    background-color: bisque;
    width: 10px;
    display: inline-block;
}
.letter.green {
    background-color: greenyellow;
}
.letter.red {
    background-color: red;
}

/*********** problems  ************/
.problem-statement, .example {
    /* background-color: #2f3538; */
    padding: 10px;
    margin-bottom: 10px;
}
.example {
    background-color: #f2f4f4;;
}
.problem-statement {
    border: solid 1px #f2f4f4;
}

/*********** min cost tickets  ************/
.day {
    padding: 4px;
    width: 30px;
    background-color: rgb(208, 243, 249);
    color: rgb(64, 69, 70);
    border-radius: 5px;
    display: inline-block;
    margin-right: 2px;
    margin-bottom: 2px;
    cursor: pointer;
    text-align: center;
}
.daywithdp {
    width: 80px;
    height: 84px;
    vertical-align: middle;    
}
.dp {
    font-size: small;
    color: rgb(113, 121, 123);    
}
.selected {
    background-color: rgb(127, 240, 255);
}

.kmp-cell {
    width: 15px;
    padding: 2px;
    display: inline-block;
}

.show-only-cells .kmp-cell {
    background-color: rgb(167, 179, 180);
    height: 24px;
}

.highlighted.kmp-cell {
    background-color: #dbffe6;
    /* color: white; */
}
.matched.kmp-cell {
    background-color: #dbffe6;
    /* color: white; */
}
.unmatched.kmp-cell {
    background-color: rgb(255, 199, 199);
    /* color: white; */
}

.opaque {
    opacity: 0;
}

/* count islands */
.dfs-node {
    background: #7cf0ff !important;
    color: white;
}
.dfs-start-node {
    background: #00bcd4 !important;
}
.selected-grid-index {
    color: #e91e63;
}
.water-cell {
    color: #ccc;
}

/* general */
.highlighted {
    background-color: var(--color-highlighted-background);
    color: var(--color-highlighted-text);
}
.arrayitem-wrapper {    
    text-align: center;
    display: inline-block;
    vertical-align: top;
}
.arrayitem {
    display: inline-block;
    vertical-align: top;
    width: 50px;
}
@media only screen and (max-device-width: 480px) { /* styles for mobile browsers smaller than 480px; (iPhone) */    
    .arrayitem {
        width: 23px;
    }
}
.arrayitem.grayedout {
    background-color: #a1b0b1;
    opacity: 0.6;
    color: black;
}

/* quicksort */
.quicksort.arrayitem {
    background-color: #a1b0b1;
    opacity: 0.6;
    color: black;
}
.quicksort.sorted {
    background: #008394 !important;
    opacity: 1;
    color: white;
}
.quicksort.smaller {
    background: red !important;
    color: white;
}
.quicksort.greater {
    background: blue !important;
    color: white;
}
.quicksort.pivot {
    background: #7900d4 !important;
    color: white;
}
/* mergesort */
.mergesorted {
    /* background: #008394 !important; */
}

/* general */

.hzflex {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: stretch;
}

.fifty {
    width: 50%;
}
.onebythree {
    width: 33%;
}
.twobythree {
    width: 66%;
}
@media only screen and (max-device-width: 480px) { /* styles for mobile browsers smaller than 480px; (iPhone) */    
    .hzflex {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: stretch;
    }
        
    .fifty, .onebythree, .twobythree {
        width: 100%;
    }
}

/* equality equations */
.equations-array .arrayitem {
    margin-right: 3px;
    padding: 5px;
}

/* array */
.array-extra-items {
    text-align: center;
    padding: 0px 2px; /* to match arrayitem */
    margin-right: 6px; /* to match arrayitem */
}
.arrow {
    color: lightcoral;
    font-size: 30px;
    margin-top: -10px;
}